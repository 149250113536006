<template>
  <div style="">
<img src="../assets/logo1.png" style="width: 100%;height: 120px;">
    <div  class="bg" style="">
      <div style="display:flex;align-items: center;width: 500px" >
        <p style="text-align: left;width: 120px;font-size: 22px">充值Id：</p>
        <input type="text" style="border-radius: 20px;width: 300px;height: 28px;text-indent: 20px" v-model="orderId">
      </div>
      <div style="display:flex;align-items: center;;width: 500px">
        <p style="text-align: left;width: 120px;font-size: 22px">验证码：</p>
        <input style="border-radius: 20px;width: 200px;height: 28px;text-indent: 20px" v-model="code">
        <div class="" style="margin-left: 10px">
          <img :src="codeUrl" @click="getCode"  style="height: 40px" />
        </div>
      </div>
      <div
          style="background-color: red;color: #fff;font-size: 18px;width: 200px;text-align: center;height: 30px;line-height: 30px;border-radius: 20px;margin: 20px auto" @click="get" >
        查询
      </div>
    </div>
    <div style="text-align: left;width: 1020px;margin: 0 auto;font-size: 20px">查询结果</div>
    <div
        style="background-image: linear-gradient(to bottom, #fcfcfc 0%, #d5d5d5 100%);height: 110px;box-sizing: border-box;padding: 25px;font-size: 18px;display: flex;flex-direction: column;justify-content: space-between;position: relative;width: 1020px;margin: 0 auto"   v-for="(item,index ) in list" :key="index" >
      <div style="display: flex">
        <div>
          订单号：{{ item.channelOrderId }}
        </div>
        <div style="margin-left: 60px" v-if="item.paySend">账号：{{item.paySend}}</div>
        <div style="margin-left: 60px" v-else>充值Id：  <span v-if="(new Date(item.payTime).getTime()>1712592000000)"> @ </span>  {{item.orderId}}</div>

        <div style="margin-left: 60px">订单状态：<span v-if="item.status==2">已支付</span><span v-if="item.status==1">待支付</span> </div>

<!--28275473-->
<!--        28236485-->
      </div>
      <div style="display: flex;">
        <div>
          订单来源：淘宝店下单
        </div>
        <div style="margin-left: 60px">订单金额：{{item.amount}}</div>
      </div>
      <div style="position: absolute;right: 35px;bottom: 35px">到账时间：{{item.payTime}}</div>
    </div>
<!--<div v-else style="text-align: center;font-size: 22px;margin-top: 20px">-->
<!--  暂无数据-->
<!--</div>-->


  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      list:[],
      zh:'',
      zh1:'',
      status:'',
      orderNo:'',
      time:'',
      mony:0,
      code:'',
      orderId:"",
      codeUrl:'',
      uuid: null,
a:''
    }
  },
  created() {
    // eslint-disable-next-line no-unused-vars

    // this.get()
    this.getCode()

  },
  methods: {
    getCode() {
      axios({
        url: "https://tb.syfeiyi.cn/api/captchaImage",
        // url: "http://192.168.1.5:9219/captchaImage",
        method: "get",

      }).then(res => {
        this.codeUrl = "data:image/gif;base64," + res.data.img;
        this.uuid = res.data.uuid;
        // console.log(res,22)

      })

      // getCodeImg().then((res) => {
      //   this.captchaEnabled =
      //       res.captchaEnabled === undefined ? true : res.captchaEnabled;
      //   if (this.captchaEnabled) {
      //
      //   }
      // });
    },
    change(e) {
      console.log(e)

    },
    sub() {
      axios({
        url: "hhttps://tb.syfeiyi.cn/api/hand/payOrder",
        // url: "http://192.168.124.25:9219/api/hand/payOrder",
        method: "post",
        data: {
          issuerId: this.bankId,
          orderAmount: this.money
        }
      }).then(res => {
        // console.log(res.data.data)
        // console.log(res.data.data.indexOf('http'))
        let istx = res.data.data.indexOf('http')

        if (istx == -1) {
          this.$message(res.data.data)
        } else {
          location.href = res.data.data
        }
      })
    },
    quxiao() {
      console.log(1)
    },
    get() {
      axios({
        url: `https://tb.syfeiyi.cn/api/ops/order/user/list?orderId=${this.orderId}&code=${this.code}&uuid=${this.uuid}`,

        // url: `https://tb.shhonghuakeji.cn/api/ops/order/user/list?orderId=${this.orderId}&code=${this.code}&uuid=${this.uuid}`,
        // url: `http://192.168.1.5:9219/ops/order/user/list?orderId=${this.orderId}&code=${this.code}&uuid=${this.uuid}`,
        method: "get",

      }).then(res => {
        // console.log(res.data)
        if(res.data.code==200){
          console.log(res.data.data)

          if(!res.data.data||res.data.data.length<=0){
            this.$message('暂无数据')
            return
          }
          this.list=res.data.data
          this.list.forEach(item=>{
            let a= new Date( item.payTime);
            var time1 = a.getTime();
            console.log(time1)
            var now = new Date(time1+4000),
                y = now.getFullYear(),
                m = now.getMonth() + 1,
                d = now.getDate(),
                x = y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d) + " " + now.toTimeString().substr(0, 8);
            item.payTime=x
            // itrm.time=res.data.data[0].payTime

          })

          this.mony=res.data.data[0].amount

          let a= new Date( this.time);
          var time1 = a.getTime();
          console.log(time1)
          var now = new Date(time1+4000),
              y = now.getFullYear(),
              m = now.getMonth() + 1,
              d = now.getDate(),
              x = y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d) + " " + now.toTimeString().substr(0, 8);
          this.time=x
          console.log(x); // 2018-05-18 00:00:00
          this.orderNo=res.data.data[0].channelOrderId
          this.status=res.data.data[0].status
          this.zh=res.data.data[0].orderId
          this.zh1=res.data.data[0].paySend
        }else {
          this.$message.error(res.data.msg)
          // console.log(res.msg)
        }




      })
  }
}}
</script>
<style  scoped>
.bg{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 1020px;
  height: 260px;
  background-image: url("../assets/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
}

</style>
